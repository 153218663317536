<template>
    <ActivityDrawer 
        v-model="visible" 
        :visibleChange="visibleChange">
        <ActivityItem v-if="actionLoading">
            <span class="flex justify-center">
                <a-spin size="small" />
            </span>
        </ActivityItem>
        <template v-if="actions">
            <ActivityItem 
                v-if="messageItem.text"
                icon="fi-rr-copy"
                @click="copyMessage()">
                Скопировать текст сообщения
            </ActivityItem>
            <template v-if="!pinMessageOn">
                <ActivityItem 
                    v-if="actions.reply && actions.reply.availability"
                    icon="fi-rr-comment" 
                    @click="replyMethod()">
                    {{$t('chat.to_answer')}}
                </ActivityItem>
                <ActivityItem 
                    v-if="actions.add_task && actions.add_task.availability"
                    icon="fi-rr-checkbox" 
                    @click="createTask()">
                    {{$t('chat.set_task')}}
                </ActivityItem>
                <ActivityItem 
                    v-if="actions.add_order && actions.add_order.availability && messageItem.message_author && user && messageItem.message_author.id !== user.id"
                    icon="fi-rr-shopping-cart-check" 
                    @click="openOrderDrawer()">
                    {{$t('chat.set_order')}}
                </ActivityItem>
                <ActivityItem 
                    v-if="actions.pin && actions.pin.availability && pinMessageShow && !messageItem.is_pinned"
                    icon="fi-rr-thumbtack"
                    @click="pinMessage()">
                    {{$t('chat.anchor')}}
                </ActivityItem>
            </template>
            <template v-if="actions.pin && actions.pin.availability && messageItem.is_pinned">
                <ActivityItem 
                    v-if="pinMessageShow"
                    icon="fi-rr-comment-xmark"
                    @click="unpinMessage()">
                    {{$t('chat.unpin')}}
                </ActivityItem>
                <ActivityItem 
                    v-if="pinMessageOn"
                    icon="fi-rr-comments"
                    @click="messSearch(messageItem)">
                    {{$t('chat.show_in_chat')}}
                </ActivityItem>
            </template>
            <ActivityItem 
                v-if="deleteBtnShow"
                redLink
                icon="fi-rr-trash"
                @click="deleteMessage()">
                {{$t('chat.remove')}}
            </ActivityItem>
        </template>
    </ActivityDrawer>
</template>

<script>
import { ActivityItem, ActivityDrawer } from '@/components/ActivitySelect'
import { onLongPress } from '@vueuse/core'
import { mapState } from 'vuex'
export default {
    components: {
        ActivityItem,
        ActivityDrawer
    },
    props: {
        messageItem: {
            type: Object,
            require: true
        },
        pinMessageOn: {
            type: Boolean,
            default: false
        },
        deleteBtnShow: {
            type: Boolean,
            default: false
        },
        pinMessageShow: {
            type: Boolean,
            default: false
        },
        replyMethod: {
            type: Function,
            default: () => {}
        },
        createTask: {
            type: Function,
            default: () => {}
        },
        pinMessage: {
            type: Function,
            default: () => {}
        },
        deleteMessage: {
            type: Function,
            default: () => {}
        },
        unpinMessage: {
            type: Function,
            default: () => {}
        },
        messSearch: {
            type: Function,
            default: () => {}
        },
        messageRef: {
            type: HTMLElement,
            required: true
        },
        isScrolling: {
            type: Boolean,
            default: false
        },
        openOrderDrawer: {
            type: Function,
            default: () => {}
        },
        copyMessage: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        ...mapState({
            config: state => state.config.config,
            user: state => state.user.user
        })
    },
    data() {
        return {
            visible: false,
            menuLoading: false,
            actions: null,
            actionLoading: false,
        }
    },
    methods: {
        visibleChange(vis) {
            if(!vis) {
                this.actions = null
            }
        },
        async getActions() {
            try {
                this.actionLoading = true
                const { data } = await this.$http.get('/chat/message/action_info/', {
                    params: {
                        message: this.messageItem.message_uid,
                        chat: this.messageItem.chat || this.messageItem.chat_uid
                    }
                })
                if(data?.actions) {
                    this.actions = data.actions
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.actionLoading = false
            }
        },
        openMobileMenu() {
            this.getActions()
            this.visible = true
        }
    }
}
</script>